import React from 'react';
import { Container } from 'react-bootstrap';
import '../../App.css';

export default function Privacy() {
  return <Container fluid className="mainPage">
    <div style={{ textAlign: "left" }}>
      <br /><br />
      <h2 style={{ textAlign: "center" }}><ol start="1"><li>Termini di servizio</li></ol></h2>

      <p>I termini di servizio aiutano a definire il rapporto tra lo Studio Medico Io Donna (di seguito <i>Studio Medico</i>) e gli utenti del servizio di Ritiro Referti online,
        stabilendo cosa l’utente può aspettarsi dallo <i>Studio Medico</i> e cosa lo <i>Studio Medico</i> può aspettarsi dagli utenti del servizio.</p>

      <p>I presenti Termini di servizio ("Termini") regolano l'accesso e l'utilizzo del sito web https://referti.iodonnastudiomedico.it.
        Leggi attentamente questi Termini e accedendo o usando il sito, accetti i presenti Termini, le nostre norme sulla Privacy,
        la nostra Informativa sui cookie.</p>

      <h3>Il nostro servizio</h3>

      <p>Nel consenso alla Privacy dello <i>Studio Medico</i> hai confermato di voler ricevere in maniera telematica i tuoi referti.</p>

      <p>Lo <i>Studio Medico</i> ti aiuta ad ottimizzare la tua esperienza di accesso ai tuoi referti sanitari telematici, in modalità conforme alla riservatezza prevista dal GDPR,
        tramite il sito https://referti.iodonnastudiomedico.it (di seguito Sito).</p>

      <p>Il sistema di autenticazione per il riconoscimento certo dell’utente dell’applicazione è stato organizzato prevedendo un sistema di sicurezza a due livelli:
        codice fiscale e pin univoco temporaneo.</p>

      <p>Quando il referto sarà disponibile, riceverai un messaggio sul tuo numero di cellulare, che hai dichiarato allo <i>Studio Medico</i>
        quando hai compilato il consenso alla privacy, con l’indicazione  del link al Sito.</p>

      <p>Per accedere al sito, potrai cliccare su questo link  oppure copiarlo su un browser sul tuo computer.
        Ti verrà quindi richiesto di inserire il tuo codice fiscale di cui ne sarà verificata l’esistenza nel repository dello <i>Studio Medico</i>.</p>

      <p>A questo punto, se il codice fiscale è esistente, riceverai sul tuo cellulare un codice temporaneo che dovrai inserire per accedere alla tua area riservata.
        Potrai quindi selezionare il referto e scaricarlo, insieme all’eventuale relativa prescrizione.</p>

      <h3>Chi può utilizzare il sito</h3>

      <p>L’accesso al Sito sarà consentito solo  alle pazienti dello <i>Studio Medico</i> che hanno richiesto esplicitamente l’invio dei referti in via telematica.</p>

      <h3>Come lo <i>Studio Medico</i> utilizza di tuoi dati</h3>

      <p>I dati che fornisci al momento della creazione del tuo accesso al Sito(Codice Fiscale) saranno gestiti conformemente con la Politica della Privacy del Sito
        (https://referti.iodonnastudiomedico.it/privacyIt) e saranno utilizzati al solo scopo di verificare se presenti nell’anagrafica dello <i>Studio Medico</i> Io Donna,
        per reperire il numero di cellulare cui inviare i messaggi di servizio.</p>

      <h3>I tuoi feedback</h3>

      <p>L’opinione dei nostri utenti è per noi molto importante e siamo sempre pronti ad ascoltarli per rendere lo <i>Studio Medico</i> Io Donna sempre migliore.
        Se decidi di inviare commenti, idee e suggerimenti accetti che possiamo utilizzarli senza alcuna restrizione e senza alcun compenso nei tuoi confronti.</p>

      <p>Per inviare i tuoi feedback, invia una mail a info@iodonnastudiomedico.it.</p>

      <h2 style={{ textAlign: "center" }}><ol start="2"><li>Servizi di terze Parti</li></ol></h2>

      <p>I servizi di terze parti di cui lo <i>Studio Medico</i> Io Donna si avvale per per il corretto funzionamento e l’erogazione del servizio di  Ritiro Referti  sono:</p>

      <ol>
        <li><p> quello di Commify Italia srl, in qualità di Responsabile del trattamento dei dati con politica conforma al GDPR, per:
          l’invio agli utenti dei messaggi telefonici, con l’avviso di disponibilità di nuovi referti / prescrizioni;
          l’invio del PIN di autenticazione(OTP), per consentire il riconoscimento dell’utente  e permettergli di accedere al servizio;</p></li>
        <li><p>quello di Amazon EC2, su cui risiedono in cloud le macchine utilizzate per la gestione dell’applicazione e dei suoi dati.
          Gli SSD(Solid State Device) che contengono l’intero sistema sono crittografati, per garantire il massimo livello di sicurezza,
          come previsto dalla certificazione ISO 27001.</p></li>
      </ol>

      <p>Lo <i>Studio Medico</i> non assume alcuna responsabilità in merito alla gestione dei servizi delle terze parti.</p>

      <h2 style={{ textAlign: "center" }}><ol start="3"><li>Esclusioni di garanzia</li></ol></h2>

      <p>Il servizio di Rilascio dei Referti dello <i>Studio Medico</i> è fornito così com'è, senza garanzia di alcun tipo, espressa o implicita.</p>

      <h2 style={{ textAlign: "center" }}><ol start="4"><li>Limitazioni di responsabilità</li></ol></h2>

      <p>Lo <i>Studio Medico</i> non sarà responsabile di alcun danno diretto, accidentale o indiretto avvenuto conseguentemente alla perdita di dati personali.</p>

      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div >
  </Container >
}
