import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";

import './Header.css';

import logo from "./logo.png";

const Header = () => {
  return (
    <Navbar bg="ligth" expand="md" fixed="top">
      <Container fluid style={{ zIndex: 2000, display: 'flex', flexAlign: 'left', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>

        <Navbar.Brand href="/" style={{ display: 'flex', flexAlign: 'left' }}>
          <img
            src={logo}
            swidth={360}
            height={100}
            className="inline-block align-top"
            alt="IDSM - IoDonna Studio Medico" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
{//
//          <Nav className="main-header" style={{ alignItems: "center", display: 'flex', flexAlign: 'left' }}>
//            <Nav.Link as={Link} className="effect1" to="/" style={{ alignItems: "center", display: 'flex', flexAlign: 'left', padding: "0 20px 0 20px" }}>Home</Nav.Link>
//            <Nav.Link className="effect1" style={{ alignItems: "center", display: 'flex', flexAlign: 'left', padding: "0 20px 0 20px" }} href="mailto:info@iodonnastudiomedico.it?subject=Richiesta di informazioni">Contattaci</Nav.Link>
//            {/* <Login user={user} setUser={setUser} cf={cf} setCf={setCf} />  */}
//
//          </Nav>
}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
