import React from 'react';
import { Container } from 'react-bootstrap';
import '../../App.css';

export default function Privacy() {
    return <Container fluid className="mainPage">
        <div style={{ textAlign: "left" }}>
            <br /><br />
            <h1 className='privacy' style={{ textAlign: "center" }}>

            INFORMATIVA<br/>
AI  CLIENTI  DELLO STUDIO<br/>
SUL  TRATTAMENTO  DEI  DATI  PERSONALI<br/>
(ai sensi del Regolamento UE n. 2016/679 - GDPR)</h1>



<p><b>Gentile Signora/e,</b></p>
<p>lo Studio Medico Privato Specialistico della Dott.ssa Chiara Di Pietro (di seguito denominato Studio Medico),
    tutela la riservatezza e l’integrità dei suoi dati personali e sensibili, nonché la loro libera circolazione,
    adottando tutte le misure per garantire la necessaria protezione, ai sensi del Regolamento UE n.2016/679 del 27 aprile 2016. 
    Questa informativa contiene tutte le informazioni relative al trattamento dei suoi dati, in forma lecita, trasparente, corretta,
    intellegibile e con linguaggio semplice e chiaro.</p>

<h2 style={{ textAlign: "center" }}>INFORMATIVA</h2>

<ol>
    <li><b>Titolare del trattamento è lo studio medico IO DONNA della Dott.ssa Chiara Di Pietro che è anche Responsabile del Trattamento.</b></li>
    <li><b>Finalità del trattamento:</b> diagnosi, cura, terapia, prevenzione, riabilitazione, altre prestazioni di natura medica o sanitaria richieste dal diretto interessato, archivio, prenotazione appuntamenti, comunicazioni con il paziente, stampa e invio di referti, invio di comunicazioni e promozione di servizi e prestazioni, dati statistici, per fini amministrativi e contabili e connessi alla prestazione contrattuale; per esercitare i diritti legali del Titolare dello studio;  inoltre per tutti gli altri obblighi imposti dalla legge o dalle normative cogenti.</li>
    <li><b>Base giuridica del trattamento:</b> Regolamento Europeo n.2016/679 del 27 aprile 2016.</li>
    <li><b>Categorie di dati personali:</b> dati personali (nome e cognome, indirizzo di residenza, data di nascita, codice fiscale, numero di cellulare e telefono, indirizzo email), relativi alla salute e/o genetici (referti clinici e sanitari di ogni genere), così come definiti nell'art. 4 del suddetto regolamento. In particolare, per poter effettuare la prestazione medica a minori, verrà richiesto il consenso alla prestazione da parte del titolare della responsabilità genitoriale. Copia o estremi di un documento di identità può essere archiviato su formato elettronico o cartaceo.</li>
    <li><b>Categorie di destinatari:</b> medico titolare dello studio, altri medici e/o operatori sanitari coinvolti nella cura del paziente, ostetriche e fisioterapiste collaboratrici con lo Studio Medico,  personale di segreteria nonché altre strutture sanitarie pubbliche o private con le quali lo Studio Medico collabora per l'erogazione di alcune prestazioni sanitarie o per l’effettuazione di esami in service; inoltre società terze, cui lo Studio Medico affida l’amministrazione e contabilità, e società o consulenti tecnici terzi che gestiscono e mantengono funzionante ed aggiornata l’infrastruttura tecnologica, compresa la strumentazione necessaria per l’erogazione della prestazione sanitaria (ecografo ed eventuali altre), e società terze di servizio per l’invio della messaggistica verso i cellulari e per l’identificazione OTP.</li>
    <li><b>Modalità di trattamento:</b> lo Studio Medico, nel rispetto della normativa vigente ed esclusivamente agli scopi sopra indicati, effettuerà il trattamento dei suoi dati utilizzando strumenti informatici (organizzati profilando gli accessi e rendendo inaccessibili i dati tramite l’utilizzo di password forti e/o crittografia) e/o cartacei (custoditi in armadi chiusi a chiave). Il calendario degli appuntamenti sarà comunque solo disponibile al medico titolare dello studio, ad altri medici e/o operatori che collaborano con lo Studio Medico e al personale di segreteria. La gestione degli appuntamenti potrà essere fatta utilizzando strumenti elettronici, tra cui anche utilizzando la piattaforma Google Calendar. Le modalità di comunicazione tra la Paziente e lo Studio Medico avvengono tramite telefono, sms, email, app (Whatsapp, Skype e simili) e web server.</li>
    <li><b>Periodo di conservazione dei dati:</b> dieci anni dalla data di concessione del consenso anche mediante backup;</li>
    <li><b>Trasferimento dati personali in Paesi terzi:</b> i dati personali e quelli sensibili (relativi alla salute e/o genetici) non saranno trasferiti e resi disponibili in Paesi terzi.</li>
    <li><b>Rifiuto del conferimento dei dati:</b> Il conferimento dei dati è facoltativo, ma il rifiuto a fornirli comporta l’impossibilità di procedere ad erogare le prestazioni richieste;</li>
    <li><b>Diritti dell'interessato:</b> l'interessato al trattamento potrà far valere tutti i diritti garantiti dagli artt. 11 e 12 del GDPR ed in particolare: proporre reclamo ad una Autorità di controllo; chiedere al Titolare del trattamento l'accesso ai dati personali, la rettifica, la cancellazione o la limitazione del trattamento che lo riguarda e di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati; revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basato sul consenso prima della revoca; sapere con quali applicazioni sw vengono gestiti i suoi dati; ottenere copia dei dati personali oggetto del trattamento ( anagrafica paziente e referti ); il diritto alla portabilità dei dati, diritto di opporsi in qualsiasi momento, per motivi connessi alla sua situazione particolare, al trattamento dei dati personali che lo riguardano.</li>
</ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </Container >
}
