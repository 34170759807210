import React from 'react';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../../App.css';

export default function Privacy() {
  return <Container fluid className="mainPage">
    <div style={{ textAlign: "left" }}>
      <br /><br />
      <h1 className='privacy'>PRIVACY</h1>

      <p>Gentile signora lei usufruisce del servizio di Ritiro referti online perché lo ha richiesto quando ha sottoscritto
        la Privacy dello Studio medico Io Donna, al momento della sua prima visita presso lo studio.</p>
      <p>Se desidera rivedere la Politica della Privacy che ha letto e accettato può cliccare su questo <Nav.Link as={Link} style={{ display: "inline", padding: 0 }}to="/PrivacyIt">link</Nav.Link></p>
      <p></p>
      <p>&nbsp;</p>
    </div>
  </Container>
}

