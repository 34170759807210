import React from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';

import image from "./referti.jpg";

const { v4: uuidv4 } = require('uuid');
// eslint-disable-next-line no-undef
const { REACT_APP_APP_SERVER } = process.env;
console.debug ("REACT_APP_APP_SERVER: " + REACT_APP_APP_SERVER );

const cfLogin = REACT_APP_APP_SERVER + '/login';
const cfCheck = REACT_APP_APP_SERVER + '/login/check';
const cfGetDirList = REACT_APP_APP_SERVER + '/login/getDirList';
const cfDownload = REACT_APP_APP_SERVER + '/login/download';
//const cfReload = REACT_APP_APP_SERVER + '/service/reload';

const Login = ({ cf, setCf }) => {

    const [pin, setPin] = React.useState();
    const [phase, setPhase] = React.useState(0);
    const [errore, setErrore] = React.useState('');
    const [referti, setReferti] = React.useState([]);
    //const [sudo, setSudo] = React.useState(false);
    const [scrambledPhone, setScrambledPhone] = React.useState('');

    const nextPhase = () => { setPhase(phase + 1) }

    
/*
    const reload = async (e) => {
        e.preventDefault();

        try {
            await axios.put(cfReload, { 'cf': cf });
            setErrore('');
        }
        catch (error) { setErrore(error.response.data); };
    }
*/
    

    const handleSubmitCf = async (e) => {
        e.preventDefault();

            /*setSudo(false)*/

        try {
            const res = await axios.put(cfLogin, { 'cf': cf });
            if (res.status === 200 || res.status === 201) {
                nextPhase();
                setScrambledPhone(res.data.scrambledPhone);
            }
            setErrore('');
        }
        catch (error) { setErrore(error.response.data); };  
    }


    const handleSubmitCodice = async (e) => {

        e.preventDefault();

        try {
            const res = await axios.post(cfCheck, { 'pin': pin, 'cf': cf });
            handleSubmitRequestList(e);
            if (res.status === 200 || res.status === 201) nextPhase();
        
            /*    if (res.status === 201) setSudo(true)   */
        
            setErrore('');
        }
        catch (error) { setErrore(error.response.data); };
    }

    const handleSubmitRequestList = async (e) => {

        e.preventDefault();

        try {
            const res = await axios.put(cfGetDirList, { 'cf': cf });
            if (res.status === 200 || res.status === 201) {
                setReferti(res.data.ret);
                setPhase(2);
                setErrore('');
            }
        }
        catch (error) { setErrore(error.response.data); };
    }

    const handleEditClick = async (e) => {
        e.preventDefault();
        const referto = (e.target.name);

        try {
            await axios.get(cfDownload, {
                params: { cf: cf, file: referto },

                responseType: "blob",

            })
                .then((response) => {
                    let fileName = referto;
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/pdf',
                            encoding: 'UTF-8'
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setErrore('');
                })
        }
        catch (error) { setErrore(error.response.data); };
    };

    return (
        <div className="App">
            <h1><p /><p />Ritiro referti</h1>
            {//!sudo ? <></> : <input type="button" onClick={reload} name="reload" value="Reload" style={{ textAlign: 'left' }} />
            }

            {phase === 2 ? <></> :
                <>
                    <img
                        src={image}
                        width={280}
                        className="inline-block align-top referti"
                        alt="IDSM - IoDonna Studio Medico" />
                </>
            }

            {phase === 0 ?
                <>
                    <form onSubmit={handleSubmitCf}
                        style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
                        <Container className="cf" style={{ display: 'flex', flexDirection: "column", justifyContent: "center" }}>

                            <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", alignItems: "center" }}>
                                <h6 style={{ display: "flex", color: "rgb(0,0,0)", flexDirection: "column", marginTop: '10px' }}>Digita il tuo codice fiscale e premi invio</h6>
                                <input className={"cf"}
                                    pattern="([A-Za-z]{6}[0-9]{2}[ABCDEHLMPRSTabcdehlmprst](0[0-9]|1[0-9]|2[0-9]|3[0-1]|4[1-9]|5[0-9]|6[0-9]|7[0-1])[A-Za-z][0-9]{3}[A-Za-z])( ([A-Za-z]{6}[0-9]{2}[ABCDEHLMPRSTabcdehlmprst](0[0-9]|1[0-9]|2[0-9]|3[0-1]|4[1-9]|5[0-9]|6[0-9]|7[0-1])[A-Za-z][0-9]{3}[A-Za-z]))?"
                                    name={"CF"}
                                    value={cf}
                                    onChange={e => setCf(e.target.value)}
                                    style={{ display: "flex", flexDirection: "column", width: "250px" }}
                                    required title="Per favore immetti il codice fiscale nel formato XXXXXX99X99X999X"                                />
                                <p />
                                <input type="submit" name="referto" value="Invio" />
                            </div>
                        </Container>
                    </form>
                </>

                : phase === 1 ?
                    <form onSubmit={handleSubmitCodice}
                        style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
                        <Container className="cf" style={{ display: 'flex', flexDirection: "column", justifyContent: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", alignItems: "center" }}>
                                <h6 style={{ display: "flex", textAlign: "center", color: "rgb(0,0,0)", flexDirection: "column", alignItem: "center", marginTop: '10px' }}>
                                    Inserisci il codice di 5 cifre che ti è stato inviato sul tuo cellulare {scrambledPhone} e premi invio</h6>
                                <input className={"codice"}
                                    pattern="[0-9]{5}"
                                    name={"pin"}
                                    value={pin}
                                    placeholder="Pin"
                                    onChange={e => setPin(e.target.value)}
                                    style={{ display: "flex", flexDirection: "column", width: "90px" }}
                                />
                                <p />
                                <input type="submit" name="codice" value="Invio" />
                            </div>
                        </Container>
                    </form>
                    : phase === 2 ?
                        <form
                            style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
                            <Container className="cf" style={{ display: 'flex', flexDirection: "column", justifyContent: "center" }}>
                                <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", alignItems: "center" }}>
                                    <h2 style={{ display: "flex", textAlign: "center", color: "rgb(0,0,0)", flexDirection: "column", alignItem: "center" }}>Seleziona il documento che ti interessa</h2>
                                    <table>
                                        <thead>
                                            <tr key={uuidv4()}>
                                                <th>Documento</th>
                                                <td style={{ width: "30px" }}></td>
                                                <th>Data di creazione</th>
                                                <th style={{ width: "30px" }}></th>
                                                <th>Scarica</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!referti ? "" : referti.map((referto) => (
                                                <tr key={uuidv4()}>
                                                    <td style={{ textAlign: "left" }}>
                                                        {referto.filename}
                                                    </td>
                                                    <td style={{ width: "30px" }}></td>
                                                    <td>
                                                        {referto.stat.mtime.substring(0, 10)}
                                                    </td>
                                                    <td style={{ width: "30px" }}></td>
                                                    <td>
                                                        <input
                                                            type="button"
                                                            name={referto.filename}
                                                            value="Scarica"
                                                            onClick={handleEditClick}
                                                            x={referto} />
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                         </tbody>
                                    </table>
                                </div>
                                <hr />
                                <h6 style={{ display: "flex", textAlign: "center", color: "rgb(0,0,0)", flexDirection: "column", alignItem: "center" }}>
                                    L'accesso ai referti online è disponibile per 45 giorni.<br />
                                    Successivamente potrà sempre esserne richiesta copia rivolgendosi direttamente allo studio.
                                </h6>    
                            </Container>
                        </form >
                        : <></>
            }
            <h2>{errore}</h2>
        </div >
    );
};

export default Login;
