import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './Footer.css';

const Footer = () => {

  return (
    <div className="footer_container"> 
          <Nav className="main-header">
            <Nav.Link as={Link} style={{padding:"0 10px 0 0"}} to="/privacyGeneric">Privacy</Nav.Link>
            <Nav.Link as={Link} style={{padding:"0 10px 0 10px"}} to="/serviceTermsIt">Termini di servizio</Nav.Link>
            <Nav.Link as={Link} style={{padding:"0 0 0 10px"}} to="/cookieIt">Cookies</Nav.Link>
          </Nav>
    </div>
  )
}

export default React.memo(Footer)