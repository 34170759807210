import * as React from "react";

import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Login from './components/Login/Login';
import PrivacyIt from './components/Footer/PrivacyIt'
import PrivacyGeneric from './components/Footer/PrivacyGeneric'
import ServiceTermsIt from './components/Footer/ServiceTermsIt';
import CookieIt from './components/Footer/CookieIt';


import "./App.css"

function App() {
 
  const [cf, setCf] = useState("");

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/privacyIt' element={<PrivacyIt />} />
        <Route path='/PrivacyGeneric' element={<PrivacyGeneric />} />
        <Route path='/cookieIt' element={<CookieIt />} />
        <Route path='/serviceTermsIt' element={<ServiceTermsIt />} />
        <Route path="/" element={<Login cf={cf} setCf={setCf} />} />
      </Routes>
      <Footer />
    </div>
  );

}

export default App;
