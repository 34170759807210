import React from 'react';
import { Container } from 'react-bootstrap';
import '../../App.css';

export default function Privacy() {
  return <Container fluid className="mainPage">
  
    <br /><br />
    <h1 className='privacy' style={{ textAlign: "center"}}>COOKIES</h1>

    <p>Il sistema di refertazione di IoDonna non fa uso di cookie.</p>

   </Container>
}

